import { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { WixCodeSdkHandlersProviderSym, WixCodeSdkParamsProviderSym } from '@wix/thunderbolt-symbols'
import { UrlChangeHandlerForPage } from 'feature-router/src/symbols'
import { locationWixCodeSdkHandlersProvider, locationWixCodeSdkParamsProvider } from './sdk/locationSdkProvider'

export const site: ContainerModuleLoader = (bind) => {
	bind(WixCodeSdkParamsProviderSym).to(locationWixCodeSdkParamsProvider)
}
export const page: ContainerModuleLoader = (bind, bindAll) => {
	bindAll([WixCodeSdkHandlersProviderSym, UrlChangeHandlerForPage], locationWixCodeSdkHandlersProvider)
}

export * from './symbols'
export * from './types'
