import { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { WixCodeSdkHandlersProviderSym, WixCodeSdkParamsProviderSym } from '@wix/thunderbolt-symbols'
import { windowWixCodeSdkHandlers, windowWixCodeSdkParamsProvider } from './sdk/windowSdkProvider'

export const site: ContainerModuleLoader = (bind) => {
	bind(WixCodeSdkParamsProviderSym).to(windowWixCodeSdkParamsProvider)
}
export const page: ContainerModuleLoader = (bind) => {
	bind(WixCodeSdkHandlersProviderSym).to(windowWixCodeSdkHandlers)
}

export * from './symbols'
export * from './types'
