import { omit } from 'lodash'
import { withDependencies, named, optional } from '@wix/thunderbolt-ioc'
import { getBrowserLanguage, hasNavigator, isSSR } from '@wix/thunderbolt-commons'
import {
	WixCodeSdkParamsProvider,
	SiteFeatureConfigSymbol,
	BrowserWindow,
	BrowserWindowSymbol,
	SdkHandlersProvider,
	ViewModeSym,
	ViewMode,
} from '@wix/thunderbolt-symbols'
import { IPopups, PopupsSymbol } from 'feature-popups'
import { IReporterApi, ReporterSymbol } from 'feature-reporter'
import { name } from '../symbols'
import { WindowWixCodeSdkFactoryData, WindowWixCodeSdkHandlers, WindowWixCodeSdkSiteConfig } from '../types'
import { Animations, IAnimations } from 'feature-animations'
import { IWindowScrollAPI, WindowScrollApiSymbol } from 'feature-window-scroll/src'
import { IMultilingual, MultilingualSymbol } from 'feature-multilingual'
import { TpaPopupSymbol, ITpaPopup, IModal, ModalSymbol } from 'feature-tpa'

function setCurrentLanguage(languageCode: string): never {
	throw new Error(`language code "${languageCode}" is invalid`)
}

export const windowWixCodeSdkHandlers = withDependencies(
	[
		optional(Animations),
		BrowserWindowSymbol,
		ViewModeSym,
		ModalSymbol,
		TpaPopupSymbol,
		WindowScrollApiSymbol,
		optional(PopupsSymbol),
		optional(ReporterSymbol),
		optional(MultilingualSymbol),
	],
	(
		animations: IAnimations,
		window: BrowserWindow,
		viewMode: ViewMode,
		{ openModal }: IModal,
		{ openPopup }: ITpaPopup,
		windowScrollApi: IWindowScrollAPI,
		popupsFeature?: IPopups,
		analyticFeature?: IReporterApi,
		multilingual?: IMultilingual
	): SdkHandlersProvider<WindowWixCodeSdkHandlers> => {
		return {
			getSdkHandlers: () => ({
				setCurrentLanguage: multilingual?.setCurrentLanguage || setCurrentLanguage,
				async scrollToComponent(compId: string, callback: Function) {
					if (!process.env.browser) {
						return
					}
					await windowScrollApi.scrollToComponent(compId)
					callback()
				},
				async scrollToHandler(x, y, shouldAnimate) {
					if (!window) {
						return
					}
					if (!shouldAnimate) {
						window.scrollTo(x, y)
					}
					return windowScrollApi.animatedScrollTo(y)
				},
				getCurrentGeolocation() {
					return new Promise((resolve, reject) => {
						if (hasNavigator(window)) {
							navigator.geolocation.getCurrentPosition(resolve, ({ message, code }) => {
								reject({ message, code })
							})
						}
					})
				},
				openModal,
				openLightbox(lightboxPageId, lightboxName, closeHandler) {
					return popupsFeature
						? popupsFeature.openPopupPage(lightboxPageId, closeHandler)
						: Promise.reject(`There is no lightbox with the title "${lightboxName}".`)
				},
				closeLightbox() {
					if (popupsFeature) {
						popupsFeature.closePopupPage()
					}
				},
				openTpaPopup: openPopup,
				trackEvent(eventName: string, params = {}, options = {}) {
					const event = { eventName, params, options }
					analyticFeature && analyticFeature.trackEvent(event)
				},
			}),
		}
	}
)

export const windowWixCodeSdkParamsProvider = withDependencies(
	[named(SiteFeatureConfigSymbol, name), BrowserWindowSymbol, optional(MultilingualSymbol)],
	(
		{ previewMode, isMobileFriendly }: WindowWixCodeSdkSiteConfig,
		window: BrowserWindow,
		multilingualData?: IMultilingual
	): WixCodeSdkParamsProvider<WindowWixCodeSdkFactoryData> => ({
		[name]: {
			initialState: {
				isSSR: isSSR(window),
				browserLocale: getBrowserLanguage(window),
				previewMode,
				isMobileFriendly,
				multilingual: omit(multilingualData, 'setCurrentLanguage'),
			},
		},
	})
)
