import { withDependencies, named } from '@wix/thunderbolt-ioc'
import { WixCodeSdkParamsProvider, SiteFeatureConfigSymbol } from '@wix/thunderbolt-symbols'
import { name } from '../symbols'
import { SiteWixCodeSdkFactoryData, SiteWixCodeSdkSiteConfig } from '../types'

const SiteWixCodeSdkParamsProvider = ({
	regionalSettings,
	siteRevision,
	language,
	mainPageId,
	appsData,
	pageIdToPrefix,
	routerPrefixes,
	baseUrl,
	timezone,
}: SiteWixCodeSdkSiteConfig): WixCodeSdkParamsProvider<SiteWixCodeSdkFactoryData> => ({
	[name]: {
		initialState: {
			siteRevision,
			regionalSettings,
			language,
			mainPageId,
			appsData,
			pageIdToPrefix,
			routerPrefixes,
			baseUrl,
			timezone,
		},
	},
})

export const siteWixCodeSdkParamsProvider = withDependencies(
	[named(SiteFeatureConfigSymbol, name)],
	SiteWixCodeSdkParamsProvider
)
