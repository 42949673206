import { withDependencies, named } from '@wix/thunderbolt-ioc'
import {
	WixCodeSdkParamsProvider,
	SiteFeatureConfigSymbol,
	ViewerModelSym,
	ViewerModel,
	CurrentRouteInfoSymbol,
	SdkHandlersProvider,
} from '@wix/thunderbolt-symbols'
import { name } from '../symbols'
import { LocationWixCodeSdkFactoryData, LocationWixCodeSdkSiteConfig } from '../types'
import { INavigation, NavigationSymbol } from 'feature-navigation'
import { ICurrentRouteInfo, IUrlHistoryManager, UrlHistoryManagerSymbol } from 'feature-router'
import { IUrlChangeListener } from 'feature-router/dist/types'

/**
 * you may depend on any symbol from https://github.com/wix-private/thunderbolt/tree/7b409d8d1b75a570fee8b84f46ce7db0d9a8bfae/packages/thunderbolt-symbols/src/symbols
 */
export const locationWixCodeSdkParamsProvider = withDependencies(
	[named(SiteFeatureConfigSymbol, name), ViewerModelSym],
	(
		{ routersConfigMap }: LocationWixCodeSdkSiteConfig,
		viewerModel: ViewerModel
	): WixCodeSdkParamsProvider<LocationWixCodeSdkFactoryData> => {
		const baseUrl = viewerModel.site.externalBaseUrl
		return {
			[name]: {
				initialState: {
					baseUrl,
					routersConfigMap,
				},
			},
		}
	}
)

export const locationWixCodeSdkHandlersProvider = withDependencies(
	[NavigationSymbol, UrlHistoryManagerSymbol, CurrentRouteInfoSymbol],
	(
		navigation: INavigation,
		urlHistoryManager: IUrlHistoryManager,
		currentRouteInfo: ICurrentRouteInfo
	): SdkHandlersProvider<any> & IUrlChangeListener => {
		const onChangeHandlers: Array<Function> = []
		let routeInfo = currentRouteInfo.getCurrentRouteInfo()

		return {
			getSdkHandlers: () => ({
				navigateTo: navigation.navigateTo,
				pushUrlState: (href: string): void => {
					const url = new URL(href)
					urlHistoryManager.pushUrlState(url)
				},
				registerLocationSdkOnChangeHandler: (handler: Function) => {
					onChangeHandlers.push(handler)
				},
			}),
			onUrlChange: (url) => {
				if (routeInfo?.pageId === currentRouteInfo.getCurrentRouteInfo()?.pageId) {
					onChangeHandlers.forEach((handler) => handler(url.href))
				}
				routeInfo = currentRouteInfo.getCurrentRouteInfo()
			},
		}
	}
)
